import React from 'react';
import styled from 'styled-components';
import ConditionalLayout from '../components/ConditionalLayout';

const Triangle1 = styled.h2`
  position: absolute;
  width: 0;
  height: 0;
  z-index: -2;
  border-bottom: 50vh solid #99eaff;
  bottom: 0;
  border-right: 50vw solid transparent;
  border-bottom-width: 20vh;
  border-right-width: 50vw;
`;
const Triangle2 = styled.h2`
  position: absolute;
  width: 0;
  height: 0;
  z-index: -2;
  border-top: 20vh solid #00aa00;
  border-left: 75vw solid transparent;
  right: 0;
  border-top-width: 40vh;
  border-left-width: 70vw;
  margin-right: 20px;
`;

const Profile = () => (
  <ConditionalLayout>
    <Triangle2 />
    <h1>会社概要</h1>
    <table className="modal">
      <tbody>
        <tr>
          <th>会社名</th>
          <td>小山商事</td>
        </tr>
        <tr>
          <th>創業</th>
          <td>1988年</td>
        </tr>
        <tr>
          <th>資本金</th>
          <td>1400万円</td>
        </tr>
        <tr>
          <th>住所</th>
          <td>〒335-0026 埼玉県戸田市新曽南2丁目14−22</td>
        </tr>
        <tr>
          <th>電話番号</th>
          <td>048-432-0705</td>
        </tr>
      </tbody>
    </table>
    <Triangle1 />
  </ConditionalLayout>
);

export default Profile;
